/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import Bowser from 'bowser';
import styles from './component.module.scss';
import chrome from './images/chrome.svg';
import safari from './images/safari.svg';
import firefox from './images/firefox.svg';
import opera from './images/opera.svg';
import edge from './images/edge.svg';

interface Browser {
  name: string;
  url: string;
  logo: string;
}

const desktopBrowserLinks: Array<Browser> = [
  {
    name: 'Chrome 56+',
    url: 'https://www.google.com/chrome/',
    logo: chrome,
  },
  {
    name: 'Safari 11+',
    url: 'https://support.apple.com/downloads/safari',
    logo: safari,
  },
  {
    name: 'Firefox 57+',
    url: 'https://www.mozilla.org/en-US/firefox/new/',
    logo: firefox,
  },
  {
    name: 'Opera 50+',
    url: 'https://www.opera.com/',
    logo: opera,
  },
  {
    name: 'Edge 16+',
    url: 'https://www.microsoft.com/en-us/windows/microsoft-edge',
    logo: edge,
  },
];

const iOSBrowserLinks: Array<Browser> = [
  {
    name: 'Safari iOS 10.2+',
    url: 'https://support.apple.com/downloads/safari',
    logo: safari,
  },
  {
    name: 'Chrome for iOS',
    url:
      'https://itunes.apple.com/us/app/chrome-web-browser-by-google/id535886823?mt=8&at=1l3v52U&ct=TomsGuide',
    logo: chrome,
  },
  {
    name: 'Firefox for iOS',
    url: 'https://itunes.apple.com/us/app/firefox-web-browser/id989804926?mt=8',
    logo: firefox,
  },
];

const mobileBrowserLinks: Array<Browser> = [
  {
    name: 'Chrome (Android)',
    url:
      'https://play.google.com/store/apps/details?id=com.android.chrome&hl=en_GB',
    logo: chrome,
  },
  {
    name: 'Firefox (Android)',
    url:
      'https://play.google.com/store/apps/details?id=org.mozilla.firefox&hl=en_GB',
    logo: firefox,
  },
  {
    name: 'Opera (Android)',
    url:
      'https://play.google.com/store/apps/details?id=com.opera.mini.native&hl=en_GB',
    logo: opera,
  },
];

interface UnsupportedBrowserProps {
  browserName: string;
  browserVersion: string;
  isAndroid: boolean;
  isiOS: boolean;
  isMobile: boolean;
  onForceAccess: () => void;
}

const UnsupportedBrowser: React.FC<UnsupportedBrowserProps> = React.memo(
  ({
    browserName,
    browserVersion,
    isAndroid,
    isiOS,
    isMobile,
    onForceAccess,
  }) => {
    const renderBrowsers = (browsers: Array<Browser>) =>
      browsers.map((browser, i, arr) => (
        <a
          href={browser.url}
          className={styles.browserItem}
          style={arr.length - 1 === i ? { marginRight: '0' } : {}}
          key={browser.name}
        >
          <img src={browser.logo} alt="browser logo" />
          <div className={styles.browserName}>{browser.name}</div>
          <div className={styles.browserDw}>download</div>
        </a>
      ));

    return (
      <div className={styles.ct}>
        <div className={styles.browserSupport}>
          <h1>Hi, please upgrade your browser</h1>
          <p>
            Hypersay is built using latest technologies to provide a fast &
            secure service.
            <br />
            Please upgrade your browser ({`${browserName} v${browserVersion}) `}
            to one of the supported versions suggested below.
          </p>
          <div className={styles.browsersCt}>
            {isiOS ? renderBrowsers(iOSBrowserLinks) : null}
            {isAndroid ? renderBrowsers(mobileBrowserLinks) : null}
            {!isMobile ? renderBrowsers(desktopBrowserLinks) : null}
            <div style={{ clear: 'both' }} />
          </div>
          <div className={styles.bottomNote}>
            <p>
              Still having problems? Please email us at&nbsp;
              <a href="mailto:hello@hypersay.com">hello@hypersay.com</a>
            </p>
            <p>
              Want to access anyway? Click{' '}
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  onForceAccess();
                }}
              >
                here
              </a>
              .
            </p>
          </div>
        </div>
        <div style={{ clear: 'both' }} />
      </div>
    );
  },
);

interface BrowserBarrierProps {
  allowUndetected?: boolean;
}

const browser = Bowser.getParser(window.navigator.userAgent);

const BrowserBarrier: React.FC<BrowserBarrierProps> = ({
  allowUndetected = false,
  children,
}) => {
  const [forceAccess, setForceAccess] = useState(false);
  const [isCurrentBrowserSupported, setIsCurrentBrowserSupported] = useState(
    true,
  );
  const [browserName, setBrowserName] = useState('unknown browser');
  const [browserVersion, setBrowserVersion] = useState('unknown version');
  const [isiOS, setIsiOS] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isBot, setIsBot] = useState(false);

  useEffect(() => {
    const isSupported = browser.satisfies({
      ios: '>10.2',
      chrome: '>=69',
      chromium: '>=69',
      firefox: '>=60',
      edge: '>=81',
      safari: '>=11',
      android: '>=11.8',
      opera: '>=60',
      samsung_internet: '>=8',
    });
    if (isSupported === undefined) {
      setIsCurrentBrowserSupported(allowUndetected);
    } else {
      setIsCurrentBrowserSupported(isSupported);
    }
  }, [allowUndetected]);

  useEffect(() => {
    if (isCurrentBrowserSupported === false && window.ga) {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'OldBrowserAccess',
        eventAction: 'acces denied',
      });
    }
  }, [isCurrentBrowserSupported]);

  useEffect(() => {
    const b = browser.getResult();
    setBrowserName(b.browser.name || 'unknown browser');
    setBrowserVersion(b.browser.version || 'unknown version');
    setIsiOS(b.os.name === 'iOS');
    setIsAndroid(b.os.name === 'Android');
    setIsMobile(b.platform.type === 'tablet' || b.platform.type === 'mobile');
    setIsBot(
      /(mediapartners|apis|adsbot)?[ -]?\(?google(bot)?/i.test(
        navigator && navigator.userAgent,
      ),
    );
  }, []);

  return isCurrentBrowserSupported || isBot || forceAccess ? (
    <>{children}</>
  ) : (
    <UnsupportedBrowser
      browserName={browserName}
      browserVersion={browserVersion}
      isAndroid={isAndroid}
      isMobile={isMobile}
      isiOS={isiOS}
      onForceAccess={() => setForceAccess(true)}
    />
  );
};

export default BrowserBarrier;
