import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import AppLoader from './components/AppLoader';
import * as serviceWorker from './serviceWorker';
import '@hypersay/ui/dist/style.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  // release: HYPERSAY_SENTRY_VERSION,
  beforeSend: event => {
    if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
      return event;
    }
    return null;
  },
});

ReactDOM.render(<AppLoader />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
