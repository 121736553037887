import React from 'react';
import FullPageLoader from '../Loaders/FullPageLoader';
import BrowserBarrier from '../BrowserBarrier';
// import './component.css';

const App = React.lazy(
  async () =>
    await import(
      /* webpackChunkName: "App" */
      '../App'
    ),
);

const AppLoader: React.FC = () => {
  return (
    <React.Suspense fallback={<FullPageLoader />}>
      <BrowserBarrier>
        <App />
      </BrowserBarrier>
    </React.Suspense>
  );
};

export default AppLoader;
