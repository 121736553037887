import React from 'react';
import logo from './logo.svg';
import logoError from './logoError.svg';

interface FullPageLoaderProps {
  error?: boolean;
  token?: string;
}

const ErrorMessage: React.FC<Pick<FullPageLoaderProps, 'token'>> = ({
  token,
}) => (
  <>
    Cannot contact the server.
    <br />
    Try to{' '}
    <a
      href="/"
      onClick={() => window.location.reload()}
      title="reload"
      style={{ color: 'inherit' }}
    >
      reload
    </a>
    &nbsp;the page or{' '}
    <a
      href={`mailto:support@hypersay.zendesk.com?subject=Error%20loading%20live%20session${
        token ? `%20token:%20${token}` : ''
      }`}
      style={{ color: 'inherit' }}
    >
      contact us
    </a>
    .
  </>
);

/**
 * Full page loader, used mainly on first load.
 * This should contain no dependencies to be loaded as fast as possible. So no UI, no css
 */
const FullPageLoader: React.FC<FullPageLoaderProps> = ({
  error = false,
  token,
}) => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'var(--payne-grey)',
        color: 'var(--white)',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
        }}
      >
        <img
          src={error ? logoError : logo}
          alt="logo"
          style={{ width: '100px', marginBottom: '1em' }}
        />
        <p style={{ textAlign: 'center' }}>
          {error ? <ErrorMessage token={token} /> : 'Loading...'}
        </p>
      </div>
    </div>
  );
};

export default FullPageLoader;
